// main.js サイトで利用するスクリプトを記載
// ./core/ jQuery本体（異なるバージョンを利用したい場合はcore内のライブラリを差し替えてください）
// ./app/ 各種プラグイン各種を格納（いくつか入れてます）

// ここからは自由に
// const es6Name = 'john';
// console.log('hello ${es6Name} . nice to meet you');

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
let sidebar;

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

function disableScroll() {
  if (window.addEventListener)
    // older FF
    window.addEventListener("DOMMouseScroll", preventDefault, false);
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove = preventDefault; // mobile
  document.onkeydown = preventDefaultForScrollKeys;
}

function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}

$(document).ready(function () {
  const ua = navigator.userAgent;
  if (
    ua.indexOf("iPhone") > -1 ||
    (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1)
  ) {
    // SP
    // console.log("is_sp");
    $("body").addClass("is_sp");
    $("body").removeClass("is_tab");
    $("body").removeClass("is_pc");
  } else {
    // PC
    // console.log("is_pc");
    $("body").addClass("is_pc");
    $("body").removeClass("is_sp");
    $("body").removeClass("is_tab");
  }
  const agent = window.navigator.userAgent.toLowerCase();
  if (
    agent.indexOf("ipad") > -1 ||
    (agent.indexOf("macintosh") > -1 && "ontouchend" in document)
  ) {
    // TAB
    //$("meta[name='viewport']").attr("content", "width=1280");
    $("body").addClass("is_tab");
    $("body").removeClass("is_sp");
    $("body").removeClass("is_pc");
  } else {
    //$("meta[name='viewport']").attr("content", "width=device-width,initial-scale=1");
    $("body").removeClass("is_tab");
  }
});

///////////////////////////////////////
//ウィンドウを閉じる
///////////////////////////////////////
$(function () {
  var winClose = $(".js_windowClose");
  winClose.on("click", function () {
    window.open("about:blank", "_self").close();
  });
});
///////////////////////////////////////
//END ウィンドウを閉じる
///////////////////////////////////////

///////////////////////////////////////
//スムーススクロール
///////////////////////////////////////
function ancScroll(headerHeight) {
  var urlHash = location.hash;
  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    //setTimeout(function () {
      var target = $(urlHash);
      if (!target.length) {
        return;
      }
      var position = target.offset().top - headerHeight;
      $("body,html").stop().animate({ scrollTop: position }, 600);
    //}, 100);
  }
  return false;
}

$(function () {
  $(document).on("click", 'a[href^="#"]', function () {
    var headerHeight = 100;
    var windowWidth = $(window).width();
    if (windowWidth <= 767) {
      headerHeight = 60;
    }
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - headerHeight;
    $("html, body").animate({ scrollTop: position }, 500);
  });
});

///////////////////////////////////////
//END スムーススクロール
///////////////////////////////////////

///////////////////////////////////////
//TOPへ戻る追従ボタン
///////////////////////////////////////

// グロナビの背景色
// $(function(){
//   //処理
//   const pos = $(window).scrollTop();
//   if(pos < 1){
//     $("body.top header").css("background", "none");
//   }else {
//     $("body.top header").css("background", "#fff");
//   }

// });
//

$(document).ready(function () {
  var scrollVal = $(window).scrollTop();
  if (scrollVal > 1) {
    $("body.top").addClass("js_scrolldown");
  } else {
    $("body.top").removeClass("js_scrolldown");
  }
  //console.log(scrollVal);
});
$(window).on("scroll", function () {
  var scrollHeight = $(document).height(); //スクロール可能高さ
  var windowHeight = window.innerHeight; //ウィンドウ高さ
  var scrollVal = $(window).scrollTop(); //スクロール量
  var windowWidth = $(window).width();
  //TOPページヘッダ装飾用class制御
  if (scrollVal > 1) {
    $("body.top").addClass("js_scrolldown");
  } else {
    $("body.top").removeClass("js_scrolldown");
  }

  //ページトップへ戻るボタン制御
  var pagetop = $(".js_toPageTop"); //ページトップへ戻るボタン要素
  if (scrollVal > 200) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }

  //ページトップへ戻るボタンをcopylight直前で止める制御

  var stopPoint = null; //止める要素の高さ取得

  var positionBtm = 30; //止める要素からの距離　初期30（スクロール時）

  //<body>に付与されたclassで以下の分類に分ける（それぞれのfooter構造が異なる為）
  //.top      = TOPページ
  //.service  = 金融機関向けページ
  //.en       = 英語ページ
  // else     = 通常ページ
  if ($("body").hasClass("top")) {
    //.top
    stopPoint = $(".copylight_inr").innerHeight();
    if (windowWidth <= 767) {
      stopPoint = $(".copylight_sp").innerHeight();
    }
  } else if ($("body").hasClass("service")) {
    //.service
    stopPoint = $(".footer_service_copylight").innerHeight();
    if (windowWidth <= 767) {
      if ($(".footer_pankuzu").length) {
        stopPoint = stopPoint + $(".footer_pankuzu").innerHeight();
      }
    }
  } else if ($("body").hasClass("en")) {
    //.en
    stopPoint = $(".footerEn_copyRight_inr").innerHeight();
  } else {
    stopPoint = $(".copylight_inr").innerHeight();
    if (windowWidth <= 767) {
      stopPoint = $(".copylight_sp").innerHeight();
    }
  }

  // ページトップへ戻るボタンを止める制御
  if (scrollHeight - (windowHeight + scrollVal) <= stopPoint) {
    positionBtm = 40;
    if ($("body").hasClass("service")) {
      positionBtm = 20;
      if (windowWidth <= 767) {
        positionBtm = 10;
      }
    } else if ($("body").hasClass("en")) {
      positionBtm = 20;
    }
    $(".js_toPageTop").css({
      position: "fixed",
      bottom: stopPoint + positionBtm,
    });
  } else {
    if (windowWidth <= 767) {
      positionBtm = 44;
    }
    $(".js_toPageTop").css({
      position: "fixed",
      bottom: positionBtm,
    });
  }
});

$(".js_toPageTop").click(function () {
  $.when($("body, html").animate({ scrollTop: 0 }, 500)).done(function () {
    //$('.js_toPageTop img').css('opacity',1);
  });
  return false;
});
///////////////////////////////////////
//END TOPへ戻る追従ボタン
///////////////////////////////////////

///////////////////////////////////////
//END 汎用TOPへ戻る
///////////////////////////////////////
$(".js_stdToTop").click(function () {
  $("body, html").animate({ scrollTop: 0 }, 500);
  return false;
});
///////////////////////////////////////
//END 汎用TOPへ戻る
///////////////////////////////////////

///////////////////////////////////////
//追従サイドバー
///////////////////////////////////////


$(window).on("load", function () {
  if ($(".ly_wrapper__col2").length) {
    var mainH = 0;
    var mainHs = 0;

    $("main .ly_cont_wrp").each(function () {
      mainH = $(this).innerHeight();
      mainHs = mainHs + mainH;
      mainHs = mainHs - 300;
    });

    var stickyH = 0;
    if ($(".sideNav").length) {
      stickyH = $(".sideNav").innerHeight();
    }
    if ($(".md_smileSide_wrp").length) {
      stickyH = $(".md_smileSide_wrp").innerHeight();
    }
    if (mainHs > stickyH) {
      if ($(".js_sticky").length) {
        sidebar = new StickySidebar(".js_sticky", {
          minWidth: 768,
          topSpacing: 40,
          containerSelector: ".ly_wrapper__col2",
          innerWrapperSelector: ".js_sticky_inr",
          resizeSensor: false,
        });
      }
    } else {
    }
  }

  // IRニュースページでstickyアップデート
  $(document).on(
    "click",
    ".eir .newsTypeController li.activeTarget",
    function (event) {
      sidebar.updateSticky();
      //console.log("tab");
      event.stopPropagation();
    }
  );
  $(document).on(
    "click",
    ".eir_controller_year .s_yearController dl.activeTarget a",
    function (event) {
      sidebar.updateSticky();
      //console.log("click");
      event.stopPropagation();
    }
  );

  // 英語版SPニュースの年度ボタン
  $(".en .s_yearController dt a").matchHeight();
});
///////////////////////////////////////
//END 追従サイドバー
///////////////////////////////////////

// PCとSPで振る舞いを分ける
// load
// DOMContentLoaded → HTML 文書の読み込みと解析が完了したとき、スタイルシート、画像、サブフレームの読み込みが完了するのを待たずに発生します。
document.addEventListener("DOMContentLoaded", function () {
  // add electrodream 20201207
  //set_sp_footer_slider();

  var mql = window.matchMedia("(min-width: 768px)");
  function touchBreakPoint(mql) {
    if (mql.matches) {
      // console.log("pc");
      menuAcc_sp_des();
      navDlopdown_pc();
      footerNav_sp_des();
      js_spAccNext_des();
      PcHeaderHeaderReSetEN();
      ancScroll(100);
      ftSliderPC();
      setFtSlideArrow(".footer_slider_wrp_PC", 4);

      navDlopdown_pc_EN();
      $(".footerEn_siteMap_list").show();
      ftPrivacy_acc_des();
      ftSecurity_acc_des();

    } else {
      // console.log("sp");
      navDlopdown_pc_des();
      SpHeaderHeaderReSetEN();
      menuBtn_sp();
      menuAcc_sp();
      footerNav_sp();
      ancScroll(60);
      ftSliderSP();
      setFtSlideArrow(".footer_slider_wrp_SP", 1);

      js_hambBtn_EN();
      js_headerToggleBtn_SP_EN();
      js_stdAccNext();
      js_spAccNext();
      $(".footerEn_siteMap_list").hide();
      $(".sitemap_cell_inr").hide();
      ftPrivacy_acc();
      ftSecurity_acc();
    }
  }
  mql.onchange = touchBreakPoint; // ブレイクポイントで発火
  touchBreakPoint(mql); // 初回
});

$('.checkbox_label input[type="checkbox"]').change(function () {
  if ($(this).is(":checked")) {
    $(this).parent("label").addClass("is_checked");
  } else {
    $(this).parent("label").removeClass("is_checked");
  }
});

$('.md_form_radio input[type="radio"]').change(function () {
  $('.md_form_radio input[type="radio"]').each(function () {
    if ($(this).is(":checked")) {
      $(this).parent("label").addClass("is_checked");
    } else {
      $(this).parent("label").removeClass("is_checked");
    }
  });
});

// スタンダードな汎用アコーディオン
// ボタンと隣接した開閉要素
function js_stdAccNext() {
  $(".js_stdAccNext_btn").each(function () {
    $(this).on("click", function (event) {
      event.preventDefault();
      if ($(this).hasClass("is_active")) {
        $(this).removeClass("is_active");
        $(this).next(".js_stdAccNext_cont").stop().slideUp();
      } else {
        $(this).addClass("is_active");
        $(this).next(".js_stdAccNext_cont").stop().slideDown();
      }
    });
  });
}

// SPのみ有効なアコーディオン
// ボタンと隣接した開閉要素
function js_spAccNext() {
  $(".js_spAccNext_cont").each(function () {
    $(this).hide();
  });
  $(".js_spAccNext_btn").each(function () {
    $(this).off("click");
    $(this).removeClass("is_active");
    $(this).on("click", function (event) {
      event.preventDefault();
      if ($(this).hasClass("is_active")) {
        $(this).removeClass("is_active");
        $(this).next(".js_spAccNext_cont").stop().slideUp(200);
      } else {
        $(this).addClass("is_active");
        $(this).next(".js_spAccNext_cont").stop().slideDown(200);
      }
    });
  });
}
//PC表示でリセット
function js_spAccNext_des() {
  $(".js_spAccNext_btn").each(function () {
    $(this).off("click");
    $(this).removeClass("is_active");
  });
  $(".js_spAccNext_cont").each(function () {
    $(this).show();
  });
}

// フッタープライバシーポリシーアコーディオン
function ftPrivacy_acc() {
  $(".js_ftPrivacy_acc_btn").on("click", function (event) {
    event.preventDefault();
    if ($(this).hasClass("is_active")) {
      $(this).removeClass("is_active");
      $(".js_ftPrivacy_acc_cont").stop().slideUp();
    } else {
      $(this).addClass("is_active");
      $(".js_ftPrivacy_acc_cont").stop().slideDown();
    }
  });
}
// ハンバーガーメニュー内 アコーディオン_SP
function ftPrivacy_acc_des() {
  $(".js_ftPrivacy_acc_btn").off("click");
  $(".js_ftPrivacy_acc_btn").removeClass("is_active");
  $(".js_ftPrivacy_acc_cont").removeClass("is_active");
  $(".js_ftPrivacy_acc_cont").hide();
}

// フッターセキュリティアコーディオン
function ftSecurity_acc() {
  $(".js_ftSecurity_acc_btn").on("click", function (event) {
    event.preventDefault();
    if ($(this).hasClass("is_active")) {
      $(this).removeClass("is_active");
      $(".js_ftSecurity_acc_cont").stop().slideUp();
    } else {
      $(this).addClass("is_active");
      $(".js_ftSecurity_acc_cont").stop().slideDown();
    }
  });
}
// ハンバーガーメニュー内 アコーディオン_SP
function ftSecurity_acc_des() {
  $(".js_ftSecurity_acc_btn").off("click");
  $(".js_ftSecurity_acc_btn").removeClass("is_active");
  $(".js_ftSecurity_acc_cont").removeClass("is_active");
  $(".js_ftSecurity_acc_cont").hide();
}

// メガドロップダウン_PC_EN
function navDlopdown_pc_EN() {
  $(".js_headerToggleBtn_en").each(function () {
    $(this).mouseenter(function () {
      $(this).find(".js_headerToggleCont_en").stop().fadeIn(100);
      $(this).addClass("is_active");
    });
    $(this).mouseleave(function () {
      $(this).find(".js_headerToggleCont_en").stop().fadeOut(400);
      $(this).removeClass("is_active");
    });
  });
}
// メガドロップダウン_Tab_EN
// メガドロップダウン要素以外をクリックで
// メガドロップダウン要素を非表示
var megDlopDwnEn = $("js_headerToggleCont_en");
$("body")
  .not(megDlopDwnEn)
  .on("click", function () {
    megDlopDwnEn.stop().slideUp();
    $(".js_headerToggleBtn_en").each(function () {
      $(this).removeClass("is_active");
    });
  });

//PCヘッダー用リセット__EN
function PcHeaderHeaderReSetEN() {
  $(".js_hambBtn_cont").show();
  $(".js_headerToggleCont_en").each(function () {
    $(this).hide();
  });
  $(".js_headerToggleBtn_en").off("click");
}

//SPヘッダー用リセット__EN
function SpHeaderHeaderReSetEN() {
  $(".js_hambBtn_cont").hide();
  $(".js_headerToggleCont_en").hide();
  $(".headerEn_spHeader").removeClass("is_active");
  $(".js_hambBtn").removeClass("is_active");
  $(".js_hambBtn")
    .find(".md_hambBtn_menuTxt")
    .find("img")
    .attr("src", "/images/icon-nav-spmenu@2x.png");
  $(".js_headerToggleBtn_en").off("mouseenter");
  $(".js_headerToggleBtn_en").off("mouseleave");
  $(".js_headerToggleBtn_en .toggleBtn").off("click");
}

// ハンバーガーメニュー開閉ボタン_SP_EN
function js_hambBtn_EN() {
  $(".js_hambBtn").off("click");
  $(".js_hambBtn").on("click", function () {
    if ($(this).hasClass("is_active")) {
      $(this).removeClass("is_active");
      $(this).parent().removeClass("is_active");
      $(this)
        .find(".md_hambBtn_menuTxt")
        .find("img")
        .attr("src", "/images/icon-nav-spmenu@2x.png");
      $(".js_hambBtn_cont").stop().fadeOut();
    } else {
      $(this).addClass("is_active");
      $(this).parent().addClass("is_active");
      $(this)
        .find(".md_hambBtn_menuTxt")
        .find("img")
        .attr("src", "/images/icon-nav-spmenu-close@2x.png");
      $(".js_hambBtn_cont").stop().fadeIn();
    }
  });
}
// ハンバーガーメニュー内 アコーディオン_SP_EN
function js_headerToggleBtn_SP_EN() {
  $(".js_headerToggleBtn_en .toggleBtn").each(function () {
    $(this).on("click", function () {
      if ($(this).hasClass("is_active")) {
        $(this).removeClass("is_active");
        $(this).next(".js_headerToggleCont_en").stop().slideUp();
      } else {
        $(this).addClass("is_active");
        $(this).next(".js_headerToggleCont_en").stop().slideDown();
      }
    });
  });
}

// メガドロップダウン_PC
function navDlopdown_pc() {
  $(".megadd_btn").each(function () {
    $(this).mouseenter(function () {
      $("body.top").addClass("is_mgdd_active");
      $(this).find(".gnav_megadd").not().fadeIn(30);
      $(this).addClass("current");
    });
    $(this).mouseleave(function () {
      $("body.top").removeClass("is_mgdd_active");
      $(this).find(".gnav_megadd").not().fadeOut(200);
      $(this).removeClass("current");
    });
  });
}
// メガドロップダウン_Tab
// メガドロップダウン要素以外をクリックで
// メガドロップダウン要素を非表示
$(".megaad_btn").removeClass("current");

//ブラウザバック時を想定して
window.addEventListener("pageshow", function(event){
  if (event.persisted) {
    //メガドロップダウンを非表示にする
    $(".megadd_btn").each(function () {

      $("body.top").removeClass("is_mgdd_active");
      $(this).removeClass("current");
      if( $("body").hasClass("is_sp") ){
        //console.log(111);
      }else{
        //console.log(222);
        $(".gnav_megadd").hide();
      }
      //SP
      //$(this).removeClass("js_ddActive");
    });
    //SPメニューリセット
    // $("body.top").removeClass("js_spnav_active");
    // $(".sp_nav_btn").removeClass("js_spnav_active");
    // $(".sp_nav_menu").find("img").attr("src", "/images/icon-nav-spmenu@2x.png");
    // $(".sp_nav_cont").hide();
  }
});

var megDlopDwn = $(".gnav_megadd");
megDlopDwn.each(function () {
  if ($(this).is(":visible")) {
    $("body")
      .not($(this))
      .on("click", function () {
        megDlopDwn.each(function () {
          $(this).fadeOut();
        });
        $(".megadd_btn").each(function () {
          $(this).removeClass("current");
        });
      });
  }
});

// ハンバーガーメニュー開閉ボタン_SP
function menuBtn_sp() {
  $(".sp_nav_btn_wrp").on("click", function () {
    if ($(this).find(".sp_nav_btn").hasClass("js_spnav_active")) {
      $(this).find(".sp_nav_btn").removeClass("js_spnav_active");
      $(this)
        .find(".sp_nav_btn")
        .parents(".sp_nav")
        .removeClass("js_sp_nav_active");
      $(this)
        .find(".sp_nav_menu")
        .find("img")
        .attr("src", "/images/icon-nav-spmenu@2x.png");
      $(".sp_nav_cont").stop().fadeOut();
      $("body").removeClass("js_spnav_active");
    } else {
      $(this).find(".sp_nav_btn").addClass("js_spnav_active");
      $(this)
        .find(".sp_nav_btn")
        .parents(".sp_nav")
        .addClass("js_sp_nav_active");
      $(this)
        .find(".sp_nav_menu")
        .find("img")
        .attr("src", "/images/icon-nav-spmenu-close@2x.png");
      $(".sp_nav_cont").stop().fadeIn();
      $("body").addClass("js_spnav_active");
    }
  });
}

// フッターナビ_SP
function footerNav_sp() {
  // let scrollTop;
  $("footer .js_footerAccBtn").each(function () {
    $(this).on("click", function (event) {
      event.preventDefault();
      if ($(this).hasClass("js_footerNav_active")) {
        $(this).removeClass("js_footerNav_active");
        $(this)
          .parent("h3")
          .next(".sitemap_cell_inr")
          .stop()
          .slideUp();
        // $("body").removeClass("noscroll").css("top", "0");
      } else {
        // scrollTop = $(window).scrollTop();
        // $("body")
        //   .addClass("noscroll")
        //   .css("top", -scrollTop + "px");
        $(this).addClass("js_footerNav_active");
        //$(this).parents('.sitemap_cell').find('.sitemap_cell_inr').stop().fadeIn();
        $(this)
          .parent("h3")
          .next(".sitemap_cell_inr")
          .animate({ height: "show" }, 400);
      }
    });
  });
}

// ハンバーガーメニュー内 アコーディオン_SP
function menuAcc_sp() {
  $(".megadd_btn").each(function () {
    $(this)
      .find(".megadd_accBtn")
      .on("click", function () {
        if ($(this).parents(".megadd_btn").hasClass("js_ddActive")) {
          $(this).parents(".megadd_btn").removeClass("js_ddActive");
          $(this).next(".gnav_megadd").stop().slideUp();
        } else {
          $(this).parents(".megadd_btn").addClass("js_ddActive");
          $(this).next(".gnav_megadd").stop().slideDown();
        }
        return false;
      });
  });
}

// 帳票ダウンロード
// アイコンへのホバーでタイトルに下線
// タイトルに下線
// PC  （マウスオン）
// TAB （タップ）
// SP  （タップ）
$(function () {
  if( $('body').hasClass('is_pc') ){
    $(".bl_noticeList_pcIcon").each(function () {
      $(this).off('touchstart');
      $(this).off('touchend');
      $(this).mouseenter(function () {
        $(this).prev(".bl_noticeList_item5_ttl").find("h4 a").addClass("is_active");
      });
      $(this).mouseleave(function () {
        $(this).prev(".bl_noticeList_item5_ttl").find("h4 a").removeClass("is_active");
      });
    });
  }else if( $('body').hasClass('is_tab') ){
    $('.bl_noticeList_pcIcon').each(function () {
      $(this).off('mouseenter');
      $(this).off('mouseleave');
      $(this).prev(".bl_noticeList_item5_ttl").find("h4 a").removeClass("is_active");
      $(this).on('touchstart', function(){
        $(this).prev(".bl_noticeList_item5_ttl").find("h4 a").css({'color':'#00204D','text-decoration':'underline'});
      });
      $(this).on('touchend', function(){
        $(this).prev(".bl_noticeList_item5_ttl").find("h4 a").css({'color':'#003C92','text-decoration':'none'});
      });
    });
  }else if( $('body').hasClass('is_sp') ){
    $('.bl_noticeList_spIcon').each(function () {
      $(this).on('touchstart', function(){
        $(this).prev("a").css({'color':'#00204D','text-decoration':'underline'});
      });
      $(this).on('touchend', function(){
        $(this).prev("a").css({'color':'#003C92','text-decoration':'none'});
      });
    });
  }
});
// });

// 金融機関向けおしらせ - ファイルリスト
// アイコンへのホバーでタイトルに下線
function FileBodyIcon_PC(){
  $(".bl_dlFileBody_icon").each(function () {
    $(this).find('.md_icon').off('touchstart');
    $(this).find('.md_icon').off('touchend');
    $(this).find('.md_fileSaiz').off('touchstart');
    $(this).find('.md_fileSaiz').off('touchend');
    $(this).mouseenter(function () {
      $(this).prev(".bl_dlFileBody_link").addClass("is_active");
    });
    $(this).mouseleave(function () {
      $(this).prev(".bl_dlFileBody_link").removeClass("is_active");
    });
  });
}
function FileBodyIcon_SP(){
  $(".bl_dlFileBody_icon").each(function () {
    $(this).off('mouseenter');
    $(this).off('mouseleave');
    $(this).find('.md_icon').on('touchstart', function(){
      $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#00204D','text-decoration':'underline'});
    });
    $(this).find('.md_icon').on('touchend', function(){
      $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#003C92','text-decoration':'none'});
    });
    $(this).find('.md_fileSaiz').on('touchstart', function(){
      $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#00204D','text-decoration':'underline'});
    });
    $(this).find('.md_fileSaiz').on('touchend', function(){
      $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#003C92','text-decoration':'none'});
    });
  });
}

// 金融機関向けおしらせ - ファイルリスト
// アイコンへのホバーでタイトルに下線
// タイトルに下線
// PC  （マウスオン）
// TAB （タップ）
// SP  （タップ）
$(function () {
  if( $('body').hasClass('is_pc') ){
    $(".bl_dlFileBody_icon").each(function () {
      $(this).find('.md_icon').off('touchstart');
      $(this).find('.md_icon').off('touchend');
      $(this).find('.md_fileSaiz').off('touchstart');
      $(this).find('.md_fileSaiz').off('touchend');
      $(this).off('touchstart');
      $(this).off('touchend');
      $(this).mouseenter(function () {
        $(this).prev(".bl_dlFileBody_link").addClass("is_active");
      });
      $(this).mouseleave(function () {
        $(this).prev(".bl_dlFileBody_link").removeClass("is_active");
      });
    });
  }else if( $('body').hasClass('is_tab') ){
    $(".bl_dlFileBody_icon").each(function () {
      $(this).off('mouseenter');
      $(this).off('mouseleave');
      $(this).on('touchstart', function(){
        //console.log(11);
        $(this).prev(".bl_dlFileBody_link").removeClass("is_active");
        $(this).prev(".bl_dlFileBody_link").css({'color':'#00204D','text-decoration':'underline'});
      });
      $(this).on('touchend', function () {
        //console.log(21);
        $(this).prev(".bl_dlFileBody_link").css({'color':'#003C92','text-decoration':'none'});
      });
    });
  }else if( $('body').hasClass('is_sp') ){
    $(".bl_dlFileBody_icon").each(function () {
      $(this).off('mouseenter');
      $(this).off('mouseleave');
      $(this).find('.md_icon').on('touchstart', function(){
        $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#00204D','text-decoration':'underline'});
      });
      $(this).find('.md_icon').on('touchend', function(){
        $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#003C92','text-decoration':'none'});
      });
      $(this).find('.md_fileSaiz').on('touchstart', function(){
        $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#00204D','text-decoration':'underline'});
      });
      $(this).find('.md_fileSaiz').on('touchend', function(){
        $(this).parent().parent('.bl_dlFileBody_icon').prev('.bl_dlFileBody_link').css({'color':'#003C92','text-decoration':'none'});
      });
    });
  }
});

// 金融機関向けおしらせ
// アイコンへのホバーでタイトルに下線
// PC  （マウスオン）
// TAB （タップ）
// SP  （タップ）
$(function () {
  if( $('body').hasClass('is_pc') ){
    $(".bl_dlList_icon_pc a").each(function () {
      $(this).off('touchstart');
      $(this).off('touchend');
    });
    $('.bl_dlList_icon_pc a').mouseenter(function () {
      $(this)
        .parent(".bl_dlList_icon_pc")
        .prev(".bl_dlList_ttl")
        .find(".bl_dlList_link")
        .addClass("is_active");
    });
    $('.bl_dlList_icon_pc a').mouseleave(function () {
      $(this)
        .parent(".bl_dlList_icon_pc")
        .prev(".bl_dlList_ttl")
        .find(".bl_dlList_link")
        .removeClass("is_active");
    });
  }else if( $('body').hasClass('is_tab') ){
    $(".bl_dlList_icon_pc a").each(function () {
      $('.bl_dlList_icon_pc a').off('mouseenter');
      $('.bl_dlList_icon_pc a').off('mouseleave');
    });
    $('.bl_dlList_icon_pc a').on('touchstart', function(){
      $(this).parent('.bl_dlList_icon_pc').prev('.bl_dlList_ttl').find('.bl_dlList_link').removeClass('is_active'),
      $(this).parent('.bl_dlList_icon_pc').prev('.bl_dlList_ttl').find('.bl_dlList_link').css({'color':'#00204D','text-decoration':'underline'})
    });
    $('.bl_dlList_icon_pc a').on('touchend', function(){
      $(this).parent('.bl_dlList_icon_pc').prev('.bl_dlList_ttl').find('.bl_dlList_link').css({'color':'#003C92','text-decoration':'none'});
    });
  }else if( $('body').hasClass('is_sp') ){
    $('.bl_dlList_icon_sp').on('touchstart', function(){
      $(this).prev('.bl_dlList_link').css({'color':'#00204D','text-decoration':'underline'});
    });
    $('.bl_dlList_icon_sp').on('touchend', function(){
      $(this).prev('.bl_dlList_link').css({'color':'#003C92','text-decoration':'none'});
    });
  }
});

// フッターナビ_SP 破壊
function footerNav_sp_des() {
  $(".sitemap_cell").each(function () {
    $(this).find(".sitemap_cell_inr").show();
    $(this).find("h3 a").removeClass("js_footerNav_active");
    $(this).find("h3 a").off("click");
  });
}

// add electrodream 20201207
// スマホ用にバナースライダーを作成
// function set_sp_footer_slider() {
//   let ftSlider = $('.footer_slider_wrp');
//   let ftSliderClone = ftSlider.get(0).cloneNode(true);
//   ftSlider.addClass('pcOnly');
//   $(ftSliderClone).addClass('spOnly');
//   $(ftSliderClone).addClass('footer_sliderSP');
//   $(ftSliderClone).removeClass('footer_sliderPC');
//   $(ftSliderClone).insertAfter('.sitemap_wrp');
// }

// メガドロップダウン_PC 破壊
function navDlopdown_pc_des() {
  $(".megadd_btn").each(function () {
    $(this).off("mouseenter");
    $(this).off("mouseleave");
  });
}
// ハンバーガーメニュー内 アコーディオン_SP 破壊
function menuAcc_sp_des() {
  $(".megadd_btn").each(function () {
    $(this).find(".megadd_accBtn").off("click");
    $(this).show();
    $(".sp_nav_cont").show();
  });
}

// ニュース タブ切り替え // TOPページ
$(function () {
  $(".news_head_tabnav li").on("click", function () {
    $(".news_head_tabnav li").removeClass("news_tab_active");
    $(".news_list_wrp ul").hide();
    $(this).addClass("news_tab_active");
    var newsTabIndex = $(".news_head_tabnav li").index(this);
    $(".news_list_wrp").find("ul").eq(newsTabIndex).fadeIn();
    return false;
  });
});

// ニュース タブ切り替え
$(function () {
  $(".bl_tabnav ul li").on("click", function () {
    $(".bl_tabnav ul li").removeClass("is_tabnav_active");
    $(".bl_newsList ul").hide();
    $(this).addClass("is_tabnav_active");
    var newsTabIndex = $(".bl_tabnav ul li").index(this);
    $(".bl_newsList").find("ul").eq(newsTabIndex).fadeIn();
  });
});

$(function () {
  $(".js_MH").matchHeight();
  $(".js_MH2").matchHeight();
  $(".js_MH3").matchHeight();
  $(".js_MHbyRowFalse").matchHeight({
    byRow: false,
  });
});

///////////////////////////////////////
//住まいるTOP
///////////////////////////////////////
$(function () {
  var hideList = ".js_smileTopMain_list li:nth-of-type(n+4)";
  $(hideList).hide();
  $(".js_smileTopMain_listOpenbtn").click(function () {
    if ($(this).parents(".un_smileTopMain_inr").hasClass("is_open")) {
      $(this)
        .parents(".un_smileTopMain_inr")
        .find($(hideList))
        .stop()
        .slideUp(500, function() {
          //sidebar.updateSticky();
        });
      $(this).parents(".un_smileTopMain_inr").removeClass("is_open");
      $(this).text("もっと見る");
    } else {
      $(this)
        .parents(".un_smileTopMain_inr")
        .find($(hideList))
        .stop()
        .slideDown(500, function() {
          //sidebar.updateSticky();
        });
      $(this).parents(".un_smileTopMain_inr").addClass("is_open");
      $(this).text("閉じる");
    }
    return false;
  });

  //サイドナビ
  //var hideSideList = ".js_smileSideArchive_list li:nth-of-type(n+4)";
  //$(hideSideList).hide();
  // $(".js_smileSideArchive_listOpenbtn").on('click', function(){
  //   if ($(this).parents(".md_smileSideArchive").hasClass("is_open")) {
  //     $(this)
  //       .parents(".md_smileSideArchive")
  //       .find($(hideSideList))
  //       .stop()
  //       .slideUp(500, function() {
  //         sidebar.updateSticky();
  //       });
  //     $(this).parents(".md_smileSideArchive").removeClass("is_open");
  //     $(this).html('<a href="#">もっと見る</a>');
  //   } else {
  //     $(this)
  //       .parents(".md_smileSideArchive")
  //       .find($(hideSideList))
  //       .stop()
  //       .slideDown(500, function() {
  //         sidebar.updateSticky();
  //       });
  //     $(this).parents(".md_smileSideArchive").addClass("is_open");
  //     $(this).html('<a href="#">閉じる</a>');
  //   }
  //   return false;
  // });

  $(".un_smileTopMain_inr").each(function () {
    var num = $(this).find(".js_smileTopMain_list").children("li").length;
    if (num < 4) {
      $(this).find(".js_smileTopMain_listOpenbtn").hide();
    }
  });
});
///////////////////////////////////////
//END 住まいるTOP
///////////////////////////////////////

///////////////////////////////////////
//スライダー
///////////////////////////////////////
//TOP KV
$(function () {
  $(".kv_slider").slick({
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    autoplaySpeed: 4000,
    speed: 1300,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: ".kv_next_custom",
    prevArrow: ".kv_prev_custom",
    dots: true,
    appendDots: ".kv_dots",
    dotsClass: "kv_slick-dots",
    responsive: [
      {
        breakpoint: 767,
      },
    ],
  });
});

///////////////////////////////////////
//TOP KV モーダル
///////////////////////////////////////
$(function(){
  $(".js_modalOpen").click(function(){
    let modalID = $(this).attr('data-kvModal');
    let ytURL = $("#" + modalID).find('iframe').attr('data-ytURL');
    $("#" + modalID).find('iframe').attr('src', ytURL);
    $("#" + modalID).fadeIn();
  });
  $(".kv_modal_close").click(function(){
    $(this).parents(".kv_modal").fadeOut();
    $('iframe').attr('src', '');
    return false;
  });
});

//通常ページFOOTER PC
let isSlickPcInitialized = false;
function ftSliderPC() {
  if (! isSlickPcInitialized) {
    $(".footer_slider_wrp_PC .footer_slider").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: '<button type="button" class="footer-slick-next">next</button>',
      prevArrow: '<button type="button" class="footer-slick-prev">prev</button>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    isSlickPcInitialized = true;
  }
}

//通常ページFOOTER SP
let isSlickSpInitialized = false;
function ftSliderSP() {
  if (! isSlickSpInitialized) {
    $(".footer_slider_wrp_SP .footer_slider").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: '<button type="button" class="footer-slick-next">next</button>',
      prevArrow: '<button type="button" class="footer-slick-prev">prev</button>',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    isSlickSpInitialized = true;
  }
}

//フッタースライダーの矢印表示制御
function setFtSlideArrow(slideElm, slideShow) {
  var lengs = $(slideElm).find("li").length;
  var slideNo = 0;
  setTimeout(function () {
    $(slideElm).find(".footer-slick-prev").hide();
  }, 1000);

  $(slideElm)
    .find(".footer_slider")
    .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
      slideNo = nextSlide;

      if (slideNo == 0) {
        $(slideElm).find(".footer-slick-prev").hide();
      } else if (slideNo == lengs - slideShow) {
        $(slideElm).find(".footer-slick-next").hide();
      } else {
        $(slideElm).find(".footer-slick-prev").show();
        $(slideElm).find(".footer-slick-prev").css("opacity", 1);
        $(slideElm).find(".footer-slick-next").show();
      }
    });
}
///////////////////////////////////////
//END スライダー
///////////////////////////////////////

// タッチデバイスでhover無効化
// var touch = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

// if ("ontouchstart" in document && "orientation" in window) {
//   const locationPath = location.pathname;
//   // console.log(locationPath);
//   if (locationPath.indexOf("recruit/career/career") !== -1) {
//     console.log("recruit");
//   } else {
//     console.log("touch");
//     try {
//       for (var si in document.styleSheets) {
//         const styleSheet = document.styleSheets[si];
//         if (!styleSheet.rules) continue;

//         for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
//           if (!styleSheet.rules[ri].selectorText) continue;

//           if (styleSheet.rules[ri].selectorText.match(":hover")) {
//             styleSheet.deleteRule(ri);
//           }
//         }
//       }
//       // eslint-disable-next-line no-empty
//     } catch (ex) {}
//   }
// }

addEventListener("DOMContentLoaded", function () {
  if (isTouchDevice()) {
    for (var i in document.styleSheets)
      if (document.styleSheets.hasOwnProperty(i)) {
        deleteRuleHover(document.styleSheets[i]);
      }
  }
  function isTouchDevice() {
    return (
      // "ontouchstart" in document.documentElement ||
      // navigator.maxTouchPoints ||
      // navigator.msMaxTouchPoints
      "ontouchstart" in document && "orientation" in window
    );
  }
  function deleteRuleHover(styleSheet) {
    try {
      var rules = styleSheet.cssRules || styleSheet.rules;
      if (rules) {
        for (var i = rules.length; i--; ) {
          var text = rules[i].selectorText;
          if (/:hover/.test(text)) {
            //console.log(text);
            styleSheet.deleteRule(i);
          }
        }
        for (var i = rules.length; i--; ) deleteRuleHover(rules[i]);
      }
    } catch (ex) {
      //console.log(ex);
    }
  }
});

// E-IR sp時のフッタ年度ボタン
$(function () {
  $(document).on("click", ".s_yearController .activeTarget a", function () {
    $("body, html").animate({ scrollTop: 0 }, 500);
    return false;
  });
});

// フッター、SPグロナビ 当社保証をご利用中のお客様へ ボタン
$(function () {
  const locationPath = location.pathname;
  if (locationPath.match("customer/index.html")) {
    $(document).on("click", ".anc01 a", function () {
      var headerHeight = 100;
      var windowWidth = $(window).width();
      if (windowWidth <= 767) {
        headerHeight = 60;
        if (
          $(".sp_nav_btn_wrp").find(".sp_nav_btn").hasClass("js_spnav_active")
        ) {
          $(".sp_nav_btn_wrp")
            .find(".sp_nav_btn")
            .removeClass("js_spnav_active");
          $(".sp_nav_btn_wrp")
            .find(".sp_nav_btn")
            .parents(".sp_nav")
            .removeClass("js_sp_nav_active");
          $(".sp_nav_btn_wrp")
            .find(".sp_nav_menu")
            .find("img")
            .attr("src", "/images/icon-nav-spmenu@2x.png");
          $(".sp_nav_cont").stop().fadeOut();
          $("body").removeClass("js_spnav_active");
        }
      }
      var position = $("#anc01").offset().top - headerHeight;
      $("body, html").animate({ scrollTop: position }, 500);
      return false;
    });
  }
});

$(document).on("click", ".eir .newsTypeController .activeTarget", function () {
  // console.log("click");
  const height = $(".eir_page_news").outerHeight();
  // console.log(height);
});


// 役員一覧アコーディオン
$(".js_officerBtn").on("click", function (event) {
  if ($(this).hasClass("is_active")) {
    $(this).removeClass("is_active");
    $(this).parents(".bl_officer_head").next(".bl_officer_body").stop().slideUp().queue(function() {
      //sidebar.updateSticky();
      $(this).parents(".bl_officer_wrp").find(".bl_officer_btn").text("略歴");
    });
  } else {
    $(this).addClass("is_active");
    $(this).parents(".bl_officer_head").next(".bl_officer_body").stop().slideDown().queue(function() {
      //sidebar.updateSticky();
      $(this).parents(".bl_officer_wrp").find(".bl_officer_btn").text("閉じる");
    });
  }
  // setTimeout(function() {
  //   sidebar.updateSticky();
  // }, 1000);
});